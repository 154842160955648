// ui/helpers.js
import Vue from 'vue';

export function maskedToken(token) {
    if (!token) return '';
    return `${token.slice(0, 4)}****`; // Show only first 4 characters and mask the rest
}

export function copyToClipboard(token) {
    navigator.clipboard.writeText(token)
        .then(() => {
            Vue.toasted.show("Token copied!", {
                type: 'success',
            });
        })
        .catch(err => {
            Vue.toasted.show('Failed to copy: ' + err.message, {
                type: 'error',
            });
        });
}
